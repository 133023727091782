import { useNavigate, useLocation } from '@remix-run/react'
import { useEffect } from 'react'
import useOnMount from 'hooks/useOnMount'
import { remixNavigationManager } from 'services/remixNavigationManager'
import { type UserContextValue } from 'services/UserProvider'
import useCrashLogger from '../hooks/useCrashLogger'
import { userState } from '../services/User/userState'
import { analytics } from '../services/Analytics/Analytics'

export const useInitClientServices = (user: UserContextValue) => {
  const init = async () => {
    userState.setUser(user)

    await analytics.init()
    if (user?.unifiedUid) {
      await analytics.identifyUser(user.unifiedUid)
    }
  }
  useOnMount(() => {
    init()
  })

  useOnMount(() => {
    const rootElement = document.getElementById('root')
    rootElement?.setAttribute('data-hydrated', 'true')
  })

  useCrashLogger()

  useRemixNavigationSync()
}

const useRemixNavigationSync = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    remixNavigationManager.update(navigate, location)
  }, [navigate, location])
}
