import { type LoaderFunctionArgs, json } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { BodyScripts, HeadScripts, KeysScript } from './scripts'
import * as config from '../../server/config'
import { requestGeoIpCountry } from '../../server/geoip.js'
import 'App.scss'
import { ExperimentServer } from 'server/amplitude.server'
import getUserSessionData from 'server/session/getUserSessionData.server'
import { UserContext, type UserContextValue } from 'services/UserProvider'
import { useInitClientServices } from './rootUtils.client'
import * as importedMeta from './meta'
import { transformServerKeys } from 'server/transformServerKeys.server'
import { translateManager } from 'services/TranslateManager'
import { getAppLanguage } from 'server/getAppLanguage.server'
import { KeysContext, type KeysValue } from 'services/KeysProvider'
import { LangContext } from 'services/LangProvider'
import { ExperimentContext } from 'services/ExperimentManager/ExperimentContext'
import { DeviceContext, type DeviceType } from 'services/DeviceContext'
import DefaultProviders from 'DefaultProviders'
import { getExperimentsUser, useInitExperimentClient } from './rootHelpers'
import { useEffect, useMemo, useState } from 'react'
import UAParser from 'ua-parser-js'
import { createHead } from 'remix-island'
import { useReloadOnNewServiceWorker } from '../hooks/useReloadOnNewServiceWorker'
import UserManager from '../services/UserManager'
import { ExperimentClient, type Variant } from '@amplitude/experiment-js-client'
import { type PricingData } from '../components/Pricing/types/PricingProvider.types'
import { PRODUCT_TYPES } from '../services/ProductTypeState'

import url from 'url'
import { ExperimentManager } from '../services/ExperimentManager/ExperimentManager'
import { getPricingItems } from '../server/pricing.server'
import { getProductType } from '../services/ProductTypesManager'
import { getPricingProductType } from '../components/Pricing/PricingProvider'
import { getRelevantVariants } from '../components/Pricing/PricingUtil'
import { forceVariantsFromURL } from '../services/ExperimentManager/Experiment.utils'
import { getUtmParamsFromSearchParams } from './rootUtils.server'

export const meta = importedMeta.meta
export const links = importedMeta.links

// User centrics scripts should appear before all other scripts in the head - so they can block 3rd party requests before they are sent
// Reference: https://usercentrics.atlassian.net/wiki/spaces/SKB/pages/223379597/How+do+I+install+the+Smart+Data+Protector+on+my+website
function getUsercentricsScripts() {
  return (
    <>
      {/* <!-- This script blocks non consented requests https://docs.usercentrics.com/#/smart-data-protector --> */}
      <script
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-ruleset-id="MU39HE-m4BxMR3"
        data-avoid-prefetch-services=""
      />
      <script src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" />
    </>
  )
}

export const Head = createHead(() => (
  <>
    <Meta />
    <Links />
    {getUsercentricsScripts()}
  </>
))

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const userAgent = request.headers.get('user-agent')

  const device = new UAParser(userAgent).getDevice()
  const deviceType: DeviceType =
    device.type === 'mobile'
      ? 'mobile'
      : device.type === 'tablet'
      ? 'tablet'
      : 'desktop'

  const countryResult = requestGeoIpCountry(request)

  if (config.redirectGermanUsers && countryResult?.country === 'DE') {
    const host = request.headers.get('host')
    if (host && !host.includes('mixtiles.de')) {
      const redirectURL = new URL(request.url)
      redirectURL.host = 'mixtiles.de'
      redirectURL.port = '' // Clear the port if present (localhost)
      return redirect(redirectURL.toString(), 302)
    }
  }

  const keys = await transformServerKeys(request, context)

  const { language, responseHeaders: languageResponseHeaders } =
    await getAppLanguage(request, context.query?.language, keys.ipCountry)

  const { user, responseHeaders } = await getUserSessionData(request)

  const experimentsUserId = user.unifiedUid || user.suggestedUnifiedUid

  // Parse UTM parameters from request URL
  const parsedUrl = url.parse(request.url)
  const urlSearchParams = new URLSearchParams(parsedUrl.query ?? '')
  const utmParams = getUtmParamsFromSearchParams(urlSearchParams)

  const features = await ExperimentServer.fetchV2(
    getExperimentsUser(experimentsUserId, language, keys.ipCountry, utmParams)
  )

  const userProvider = {
    getUser: () =>
      getExperimentsUser(
        experimentsUserId,
        language,
        keys.ipCountry,
        utmParams
      ),
  }

  const experimentClient = new ExperimentClient(keys.amplitudeDeploymentKey, {
    fetchTimeoutMillis: 1000,
    pollOnStart: false,
    initialVariants: features,
    userProvider,
  })

  const serverExperimentManager = new ExperimentManager(experimentClient)

  forceVariantsFromURL(urlSearchParams, serverExperimentManager)

  const pathname = parsedUrl.pathname ?? '/'
  const productType = getProductType(pathname) || PRODUCT_TYPES.CLASSIC

  const pricingProductType = getPricingProductType(productType)
  const pricingVariantsIds = getRelevantVariants(serverExperimentManager)

  const { pricingItems, currency } = await getPricingItems({
    productType: pricingProductType,
    pricingCountry: keys.ipCountry,
    variantsIds: pricingVariantsIds,
  })

  const initialPricingData: PricingData = {
    pricingItems,
    currency,
    isLoading: true,
    productType,
  }

  const headers = new Headers()
  if (languageResponseHeaders) {
    Object.entries(languageResponseHeaders).forEach(([key, value]) => {
      headers.append(key, value)
    })
  }
  if (responseHeaders) {
    Object.entries(responseHeaders).forEach(([key, value]) => {
      headers.append(key, value)
    })
  }

  return json(
    {
      keys,
      user,
      language,
      features,
      deviceType,
      experimentsUserId,
      initialPricingData,
      utmParams,
    },
    { headers }
  )
}

function RootLayout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')

  if (!data) {
    // Error state layout
    return (
      <>
        <div id="root">{children}</div>
        <Scripts />
      </>
    )
  }

  return <MainLayout data={data}>{children}</MainLayout>
}

interface MainLayoutData {
  keys: KeysValue
  user: UserContextValue
  language: string
  features: Record<string, Variant>
  deviceType: DeviceType
  experimentsUserId: string
  initialPricingData: PricingData
  utmParams: Record<string, string>
}

function MainLayout({
  children,
  data,
}: {
  children: React.ReactNode
  data: MainLayoutData
}) {
  const {
    keys,
    user: initialUser,
    language,
    features,
    deviceType,
    experimentsUserId,
    initialPricingData,
    utmParams,
  } = data

  const [user, setUser] = useState(initialUser)

  const deviceContextValue = useMemo(() => ({ type: deviceType }), [deviceType])

  const experimentClient = useInitExperimentClient({
    features,
    keys,
    userId: experimentsUserId,
    language,
    utmParams,
  })

  if (!translateManager.didInit) {
    translateManager.init(language)
  }

  if (useInitClientServices) {
    useInitClientServices(user)
  }

  useEffect(() => {
    UserManager.subscribeToUserChange((user: UserContextValue) => setUser(user))
  }, [])

  return (
    <>
      <DeviceContext.Provider value={deviceContextValue}>
        <ExperimentContext.Provider value={experimentClient}>
          <LangContext.Provider value={language}>
            <KeysContext.Provider value={keys}>
              <UserContext.Provider value={user}>
                <DefaultProviders initialPricingData={initialPricingData}>
                  <div id="root">{children}</div>
                </DefaultProviders>
              </UserContext.Provider>
            </KeysContext.Provider>
          </LangContext.Provider>
        </ExperimentContext.Provider>
      </DeviceContext.Provider>

      <KeysScript keys={keys} />

      <ScrollRestoration />
      <Scripts />

      <HeadScripts keys={keys} />
      <BodyScripts keys={keys} />

      <img
        width="0"
        height="0"
        style={{ display: 'none', visibility: 'hidden' }}
        src="/api/web-app-load"
      />
    </>
  )
}

// @ts-ignore
export const Layout = withSentry(RootLayout)

export default function App() {
  useReloadOnNewServiceWorker()
  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)

  captureRemixErrorBoundaryError(error)

  return <div>Error</div>
}
